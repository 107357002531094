import React, { useEffect, useRef } from 'react'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
// import Feedbackimg from '../../src/assets/IMAGES/feeback.jpg'
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import { Button } from 'react-bootstrap';
import { user_post_data } from '../../src/admin/adminpages/ApiServices';
const Contact = () => {
  const animationElements = useRef([]);

  const checkIfInView = () => {
    const windowHeight = window.innerHeight;
    const windowTopPosition = window.scrollY;
    const windowBottomPosition = windowTopPosition + windowHeight;

    animationElements.current.forEach(element => {
      const elementHeight = element.offsetHeight;
      const elementTopPosition = element.offsetTop;
      const elementBottomPosition = elementTopPosition + elementHeight;

      if (
        elementBottomPosition >= windowTopPosition &&
        elementTopPosition <= windowBottomPosition
      ) {
        element.classList.add('in-view');
      } else {
        element.classList.remove('in-view');
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkIfInView);
    window.addEventListener('resize', checkIfInView);
    checkIfInView(); // Initial check

    return () => {
      window.removeEventListener('scroll', checkIfInView);
      window.removeEventListener('resize', checkIfInView);
    };
  }, []);

  //api
  const addContactUs = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      message: "",
      is_website: true, 
    },
  
    onSubmit: async (values, { resetForm }) => {
      console.log("Form values:", values); // Log form values
  
      try {
        const res = await user_post_data('/contact', values);
  
        if (res.data.status) {
          console.log("Success:", res.data.message); // Log success message
          resetForm(); // Reset the form after successful submission
          window.location.href = '/';
        } else {
          console.log("Error:", res.data.message); // Log error message
          // Handle error in a different way if needed
        }
      } catch (error) {
        console.error("Error:", error.response.data.message); // Log network or server error
        // Handle network or server error in a different way if needed
      }
    },
  });
  return (
    <>
      <section className='breakcrumb'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-12'>
              <h1 className='roboto-medium text-white'>Contact Us</h1>
              <h5 className='text-white'>(Directorate Of Rural Sanitation, Department of Rural Development & Panchayati Raj ,J&K)</h5>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container-fluid animation-element slide-up testimonial' ref={(el) => {
          if (el && !animationElements.current.includes(el)) {
            animationElements.current.push(el);
          }
        }}>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6598.953153611829!2d75.48944454305811!3d34.21084776488694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38e24fca96056755%3A0xdfd15d625912515e!2sShri%20Amarnath%20Cave%20Temple!5e0!3m2!1sen!2sin!4v1718276730997!5m2!1sen!2sin" className='w-100 height-450'  style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    {/* <div className='container'>
      <div className='row align-items-center justify-content-center'>
        <div className='col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12'>
          <div className='card-contact'>
          <Form onSubmit={addFeedback.handleSubmit}>
          <div className='row feedback'>
          <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3'>
                  <Form.Label className='roboto-regular'>First Name</Form.Label>
                  <Form.Control placeholder="First Name" className="p-3" required
                      name="first_name"
                      value={addContactUs.values.first_name}
                      onChange={addContactUs.handleChange}
                      onBlur={addContactUs.handleBlur} />
                </div>
          <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3'>
                  <Form.Label className='roboto-regular'>Last Name</Form.Label>
                  <Form.Control placeholder="Last Name" className="p-3" required
                      name="last_name"
                      value={addContactUs.values.last_name}
                      onChange={addContactUs.handleChange}
                      onBlur={addContactUs.handleBlur} />
                </div>
          <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3'>
                  <Form.Label className='roboto-regular'>Email</Form.Label>
                  <Form.Control placeholder="Email" className="p-3" required
                      name="email"
                      value={addContactUs.values.email}
                      onChange={addContactUs.handleChange}
                      onBlur={addContactUs.handleBlur} />
                </div>
          <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3'>
                  <Form.Label className='roboto-regular'>Comment</Form.Label>
                  <FloatingLabel controlId="floatingTextarea2" label="Comments">

        <Form.Control placeholder="Leave a comment here" required
                  style={{ height: '100px' }} className="p-3" 
                name="message"
                value={addContactUs.values.message}
                onChange={addContactUs.handleChange}
                onBlur={addContactUs.handleBlur} />
            
              </FloatingLabel>
                </div>
                <div className='row'>
                <div class="mt-4 d-flex justify-content-center">
                <Button type="submit" variant="blue" className=" btn-blue roboto-medium">Submit</Button></div>
                </div>
                </div>
                </Form>
          
          </div>
        </div>
      </div>
    </div> */}

    <div className='container mt-5'>
      <div className='row align-items-center justify-content-center'>
    <div className='col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12'>
      <div className='row'>
        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
        <p className='roboto-medium color-blue'><b>For all queries, please contact:
        </b></p>
        <p  className='roboto-medium '>Reception cum Information Centre</p>
        <p  className='roboto-medium color-blue'>Shri Amarnath Ji Yatra</p>
        <p className='roboto-medium '><b className='color-blue'>Help Line Contact No.:<br></br>
        </b>Short Code 14464, Toll free nos.18001807198,18001807199 (May to October)</p>
        <p className='roboto-medium'><b className='color-blue'>Help Line Contact No. :<br></br>
        </b><a href="tel:+4733378901"></a>+91-191-2555662, 2503399 (November to April)</p>
        <p className='roboto-medium '><b className='color-blue'>Email id :<br></br>
        </b>sasbjk2001@gmail.com</p>
        </div>
        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
          <h5 className='roboto-medium color-blue'>Shri Amarnath Ji Yatra</h5>
          <p className='roboto-medium '>2nd Floor, Block- III, Engineering Complex, Raj Bagh Srinagar,
          Pin Code: 190008,<br></br>
          <b className='color-blue'>May to October,</b></p>
          <p className='roboto-medium '><b className='color-blue'>Phone No. :<br></br>
          </b>Short Code 14464, Toll free nos.18001807198,18001807199</p>
          <p className='roboto-medium '><b className='color-blue'>Tele Fax :<br></br>
          </b>+91-194-2501679</p>
          <p className='roboto-medium '><b className='color-blue'>Email :<br></br>
          </b>sasbjk2001@gmail.com</p>

          <p className='roboto-medium  mt-3'>Chaitanya Ashram, Talab Tillo, Jammu,
          Pin Code: 180002,<br></br><b className='color-blue'>November to April,</b></p>
          <p className='roboto-medium '><b className='color-blue'>Phone No. :<br></br>
          </b>+91-191-2555662, 2503399</p>
          <p className='roboto-medium '><b className='color-blue'>Tele Fax: <br></br>
          </b> 0191-2503399,</p>
          <p className='roboto-medium '><b className='color-blue'>Email :<br></br>
          </b> sasbjk2001@gmail.com</p>
          </div>
      </div>
      </div>
      </div>
    </div>
      </section>


    </>
  )
}


export default Contact
