import React, { useEffect, useRef } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import pdfimg from '../assets/IMAGES/pdf.png'
import iec1 from '../assets/IMAGES/ice/iec-1-1.jpeg'
import iec2 from '../assets/IMAGES/ice/iec-1-2.jpeg'
import iec3 from '../assets/IMAGES/ice/iec-1-3.jpeg'
import iec4 from '../assets/IMAGES/ice/iec-1-4.jpeg'
import iec5 from '../assets/IMAGES/ice/iec-2.jpeg'
import iec6 from '../assets/IMAGES/ice/iec-3.png'
import iec7 from '../assets/IMAGES/ice/iec-4.jpg'
import iec8 from '../assets/IMAGES/ice/iec-5.jpeg'
import iec9 from '../assets/IMAGES/ice/iec-6.jpeg'
import iec10 from '../assets/IMAGES/ice/iec-7.jpeg'
import iec11 from '../assets/IMAGES/ice/iec-8.jpeg'
import iec12 from '../assets/IMAGES/ice/iec-9.jpeg'
import ice from '../assets/IMAGES/ice/ice-unique.jpeg'
import shiva from '../assets/IMAGES/om.png'
import speaker from '../assets/IMAGES/speaker.png'
import audionew from '../assets/audio/WhatsApp Audio 2024-06-24 at 12.03.56 PM.mpeg'

import { NavLink } from 'react-router-dom';
const IEC = () => {
  // animation
  const animationElements = useRef([]);

  const checkIfInView = () => {
    const windowHeight = window.innerHeight;
    const windowTopPosition = window.scrollY;
    const windowBottomPosition = windowTopPosition + windowHeight;

    animationElements.current.forEach(element => {
      const elementHeight = element.offsetHeight;
      const elementTopPosition = element.offsetTop;
      const elementBottomPosition = elementTopPosition + elementHeight;

      if (
        elementBottomPosition >= windowTopPosition &&
        elementTopPosition <= windowBottomPosition
      ) {
        element.classList.add('in-view');
      } else {
        element.classList.remove('in-view');
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkIfInView);
    window.addEventListener('resize', checkIfInView);
    checkIfInView(); // Initial check

    return () => {
      window.removeEventListener('scroll', checkIfInView);
      window.removeEventListener('resize', checkIfInView);
    };
  }, []);
  return (

    <>
      <section className='breakcrumb'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-12'>
              <h1 className='roboto-medium text-white'>IEC Material</h1>
              <h5 className='text-white'>(Directorate Of Rural Sanitation, Department of Rural Development & Panchayati Raj ,J&K)</h5>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='container mt-5 animation-element slide-up testimonial' ref={(el) => {
          if (el && !animationElements.current.includes(el)) {
            animationElements.current.push(el);
          }
        }}>
          <div className='col-12'>
            <div className='col-xl-7 col-lg-8 col-md-12 col-sm-12 col-12 my-3'>
              <div className='row d-flex align-items-center'>
                <div className='col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2'>
                  <img src={shiva} className='w-100' alt="" />
                </div>
                <div className='col-xl-11 col-lg-11 col-md-11 col-sm-10 col-10'>
                  <h3 className='roboto-medium color-blue mb-0'>Launching  of Logo, Anthem,  website and Yatri Apps</h3>
                </div>
              </div>
            </div>
            <img src={ice} alt="" className='w-100' />
          </div>
          <div className='row'>

          </div>
        </div>
      </section>
      <section>
        <div className='container mt-5 animation-element slide-up testimonial' ref={(el) => {
          if (el && !animationElements.current.includes(el)) {
            animationElements.current.push(el);
          }
        }}>
          <div className='row'>
            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-2'>
              <div className='row d-flex align-items-center mb-3'>
                <div className='col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2'>
                  <img src={shiva} className='w-100' alt="" />
                </div>
                <div className='col-xl-11 col-lg-11 col-md-11 col-sm-10 col-10'>
                  <h3 className='roboto-medium color-blue mb-0'>Shri Amarnath ji Yatra 2024 Anthem</h3>
                </div>
              </div>
              <iframe className='w-100' height="400" src="https://www.youtube.com/embed/K2c7vqQkKbU?si=gHDI4IEwdbV0QGkt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-2'>
              <div className='row d-flex align-items-center mb-3'>
                <div className='col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2'>
                  <img src={shiva} className='w-100' alt="" />
                </div>
                <div className='col-xl-11 col-lg-11 col-md-11 col-sm-10 col-10'>
                  <h3 className='roboto-medium color-blue mb-0'>Yatra jingle</h3>
                </div>
              </div>
           
                <div className='card-section-third'>
                <div className='row'>
                <div className='col-xl-2 col-lg-2 col-md-3 col-sm-3 col-3'>
                  <img src={speaker} alt="" className='w-100' />
                </div>
                <div className='col-xl-10 col-lg-10 col-md-9 col-sm-9 col-9'>
                <audio src={audionew} controls className='w-100'></audio>
                </div>
                </div>
              
              </div>
            </div>
          </div>
          <div className='row'>

          </div>
        </div>
      </section>

      <section>
        <div className='container mt-5 animation-element slide-up testimonial' ref={(el) => {
          if (el && !animationElements.current.includes(el)) {
            animationElements.current.push(el);
          }
        }}>
          <div className='row'>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 mb-3 '>
              <img src={iec1} className='w-100 height-350' />
            </div>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 mb-3 '>
              <img src={iec2} className='w-100 height-350' />
            </div>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 mb-3 '>
              <img src={iec3} className='w-100 height-350' />
            </div>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 mb-3 '>
              <img src={iec4} className='w-100 height-350' />
            </div>


            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 mb-3 '>
              <img src={iec5} className='w-100 height-350' />
            </div>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 mb-3 '>
              <img src={iec6} className='w-100 height-350' />
            </div>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 mb-3 '>
              <img src={iec7} className='w-100 height-350' />
            </div>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 mb-3 '>
              <img src={iec8} className='w-100 height-350' />
            </div>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 mb-3 '>
              <img src={iec9} className='w-100 height-350' />
            </div>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 mb-3 '>
              <img src={iec10} className='w-100 height-350' />
            </div>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 mb-3 '>
              <img src={iec11} className='w-100 height-350' />
            </div>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 mb-3 '>
              <img src={iec12} className='w-100 height-350' />
            </div>

          </div>
        </div>
      </section>

    </>
  )
}

export default IEC
