import React from 'react'
import FrontHeader from './FrontHeader'
import FrontFooter from './FrontFooter'
import { Outlet } from 'react-router-dom'

const FrontLayout = () => {
    return (
        <div>
            <FrontHeader></FrontHeader>
            <Outlet />
            <FrontFooter></FrontFooter>
        </div>
    )
}

export default FrontLayout
