import React, {useEffect,useRef}from 'react'
import map1 from '../assets/IMAGES/Amarnath-Routes.jpeg'
import amarnathmap from '../assets/IMAGES/route-map.png'

const Maps = () => {
  // animation
  const animationElements = useRef([]);

  const checkIfInView = () => {
    const windowHeight = window.innerHeight;
    const windowTopPosition = window.scrollY;
    const windowBottomPosition = windowTopPosition + windowHeight;

    animationElements.current.forEach(element => {
      const elementHeight = element.offsetHeight;
      const elementTopPosition = element.offsetTop;
      const elementBottomPosition = elementTopPosition + elementHeight;

      if (
        elementBottomPosition >= windowTopPosition &&
        elementTopPosition <= windowBottomPosition
      ) {
        element.classList.add('in-view');
      } else {
        element.classList.remove('in-view');
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkIfInView);
    window.addEventListener('resize', checkIfInView);
    checkIfInView(); // Initial check

    return () => {
      window.removeEventListener('scroll', checkIfInView);
      window.removeEventListener('resize', checkIfInView);
    };
  }, []);
  return (
    <>
    <section className='breakcrumb'>
      <div className='container'>
        <div className='row'>
          <div className='col-xl-12'>
          <h1 className='roboto-medium text-white'>Maps</h1>
          <h5 className='text-white'>(Directorate Of Rural Sanitation, Department of Rural Development & Panchayati Raj ,J&K)</h5>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='container mt-5 animation-element slide-up testimonial' ref={(el) => {
        if (el && !animationElements.current.includes(el)) {
          animationElements.current.push(el);
        }
      }}>

<div className='row my-5 justify-content-center'>
    <div className='col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mb-3 d-flex justify-content-center'>
      <img src={map1} className='w-100 ' alt="" />
    </div>
   
</div>

<div className='row'>
  <div className='col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12'>
  <h3 className='roboto-medium color-blue mb-3 text-center'>Amarnath Yatra Routes: All You Need to Know</h3>
<p>One of the awaited journeys of the year, Amarnath Yatra is organized in the months from July to August as per the Shravan month in the Hindu calendar. Families, friends, and companions embark on a spiritual Amarnath yatra route to find peace, enlightenment, prosperity, and most importantly blessings of the supreme being, Lord Shiva.
  <br></br>
</p>
<h5 className='roboto-medium color-blue mb-3'>How to reach Amarnath</h5>
<p>Before we discuss the routes, let’s see how you can reach Amarnath –
  <br></br>
  <br></br>
  From Delhi, Amarnath is located 971 km away, which can be covered by road. There is no railway station at Amarnath and the nearest one is located in Jammu, which is approx. 580 km away (10.5 hours drive). From Jammu, Amarnath is around 176 km
</p>
<b>Here are some ways to reach Amarnath from major cities in India –</b>
<ul className='mt-2'>
<li><strong>By Bus</strong> – Book a bus ticket from Delhi to Amarnath online. The buses are categorized as A/C, non-A/C, and Volvo Bus. Private as well as government buses run on this route. If coming from other cities like Mumbai, Chennai, Hyderabad, Kolkata, and Jaipur, a road trip to Amarnath Cave will consume a lot of time. So, it is recommended to travel by air or rail. The state transport and private operators provide bus services as well from Jammu to Pahalgam and Baltal.</li>
<li><strong>By Train </strong>– Traveling to Amarnath by train is a good choice. The nearest railhead is at Jammu, which is connected to prominent Indian cities. Jammu Rajdhani, Himgiri Express, Sarvodaya Express, and Jhelum Express are some trains that run between Mumbai, Chennai, Hyderabad, etc., and Jammu.</li>
<li><strong>By Air</strong> – Flying to Srinagar Airport for Amarnath Yatra is the best way to come. The airport is around 90 km from Pahalgam, which is the base camp for Amarnath Yatra. Jet Airways, Indigo, and Air India connect major cities of India to Srinagar Airport.</li>
</ul>
  </div>
  <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'>
    <img src={amarnathmap} className='w-100 mb-3' alt="" />
  </div>

<h4 className='color-blue'><span>1. Baltal to Amarnath Route</span></h4>
<p><strong>Route Map</strong>: Baltal – Domali – Barari – Sangam – Amarnath Cave</p>
<p>Amarnath Baltal route starts from Baltal to Domali. The distance is around 2 km. From here, there is another 6 km trek to Barari and afterward, 4 km more to Sangam. This leaves only 2 km to reach your final destination, Amarnath Cave.</p>
<p>This is the shorter route and takes only 1 to 2 days to complete (round trip). Baltal to Amarnath Cave distance is about 14 km, which is covered by a trek.</p>
<p>Remember that this route is a bit difficult and considering the weather conditions and region’s terrain in mind, it is not recommended for elderly citizens. Keep in mind that there are no ponies available on this route. You can either walk or opt for Dandies.</p>
<h4 className='color-blue'><span >2. Pahalgam to Amarnath Route</span></h4>
<p><strong>Route Map</strong>: Pahalgam – Chandanwadi – Pissu Top – Zoji Bal – Naga Koti – Sheshnag – Warbal – Mahagunas Top – Pabibal – Panchtarni – Sangam – Cave</p>
<p>Amarnath Yatra from Pahalgam starts from the base camp at Pahalgam to Chandanwari. This distance between them is approx. 16 km. Then from Chandanwari, a 13 km trek takes you to Sheshnag and a further 4.6 km trek to Panchtarni. From here, a 2 km walk will take you to Lord Shiva’s abode, Amarnath Cave.</p>
<p>Pahalgam to Amarnath Cave distance is approx. 36 to 48 km and completes in around 3 to 5 days (one way). Most of the pilgrims prefer this route as it is not only a bit easier than the Baltal one but also scenic. So, if you have senior devotees with you, this is the better route. Plus, you can also hire ponies to make their journey easier.</p>
<h3 className='color-blue'><span >Amarnath Helicopter Routes</span></h3>
<p>Those who do not want to cover Amarnath Yatra on foot can also opt for Amarnath helicopter services. Tourism of India arranges helicopter services to Amarnath from Baltal and Pahalgam helipad. Plus, our Amarnath tour packages 2024 will also provide you with swift transfers, meals, comfortable accommodations, and sightseeing.</p>
<p><strong>There are two Amarnath Helicopter routes –&nbsp;</strong></p>
<ul>
<li><em>Baltal – Panchtarni – Baltal route (2 km trek to Holy Cave from Panchtarni)</em></li>
<li><em>Pahalgam – Panchtarni – Pahalgam route (6 km trek to Holy Cave from Panchtarni)</em></li>
</ul>
</div>
        </div>
        </section>

      
    </>
  )
}

export default Maps
