import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
import HighchartsReact from 'highcharts-react-official';

Highcharts3D(Highcharts);

function BarChart() {
    useEffect(() => {
        const chart = new Highcharts.Chart({
            chart: {
                renderTo: 'container',
                type: 'column',
                options3d: {
                    enabled: true,
                    alpha: 15,
                    beta: 15,
                    depth: 50,
                    viewDistance: 25
                }
            },
            xAxis: {
                categories: [
                    'Toyota', 'BMW', 'Volvo', 'Audi', 'Peugeot', 'Mercedes-Benz',
                    'Volkswagen', 'Polestar', 'Kia', 'Nissan'
                ]
            },
            yAxis: {
                title: {
                    enabled: false
                }
            },
            tooltip: {
                headerFormat: '<b>{point.key}</b><br>',
                pointFormat: 'Cars sold: {point.y}'
            },
            title: {
                text: 'Sold passenger cars in Norway by brand, January 2021',
                align: 'left'
            },
            subtitle: {
                text: 'Source: ' +
                    '<a href="https://ofv.no/registreringsstatistikk" target="_blank">OFV</a>',
                align: 'left'
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                column: {
                    depth: 25
                }
            },
            series: [{
                data: [1318, 1073, 1060, 813, 775, 745, 537, 444, 416, 395],
                colorByPoint: true
            }]
        });

        function showValues() {
            document.getElementById(
                'alpha-value'
            ).innerHTML = chart.options.chart.options3d.alpha;
            document.getElementById(
                'beta-value'
            ).innerHTML = chart.options.chart.options3d.beta;
            document.getElementById(
                'depth-value'
            ).innerHTML = chart.options.chart.options3d.depth;
        }

        document.querySelectorAll(
            '#sliders input'
        ).forEach(input => input.addEventListener('input', e => {
            chart.options.chart.options3d[e.target.id] = parseFloat(e.target.value);
            showValues();
            chart.redraw(false);
        }));

        showValues();
    }, []);

    return (
        <div>
            <div id="container" style={{ height: '400px' }}></div>
            <div id="sliders">
                <table>
                    <tbody>
                        <tr>
                            <td>Alpha</td>
                            <td><input id="alpha" type="range" min="0" max="45" step="1" defaultValue="15" /></td>
                            <td><span id="alpha-value"></span></td>
                        </tr>
                        <tr>
                            <td>Beta</td>
                            <td><input id="beta" type="range" min="-45" max="45" step="1" defaultValue="15" /></td>
                            <td><span id="beta-value"></span></td>
                        </tr>
                        <tr>
                            <td>Depth</td>
                            <td><input id="depth" type="range" min="20" max="100" step="1" defaultValue="50" /></td>
                            <td><span id="depth-value"></span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default BarChart;
