import CryptoJS from "crypto-js";
import moment from "moment/moment"

const ADMIN_TOKEN_KEY = 'ff591090-0805-4282-8493-e9435a4d3d67';
const ENTITY_TOKEN_KEY = '4b251fb9-5643-4127-9663-712496011004';
const ALERT_MSG_KEY = 'ff5-e943591090-0805-4282-8493a4d3d67';
const ALERT_TYPE_KEY = 'ff35a4d3591090-0805-4282-8493-e94d67';
const ALERT_CODE_KEY = 'ff582-8493-e9435a4d91090-0805-423d67';
const REG_RESUME_DATA_KEY = '4850b0ae-7d07-4650-8137-5e5f4b0a9620';

const set_admin_logged = (data) => {
    const temp = JSON.stringify(data);
    var enc = encrypt(temp);
    localStorage.setItem(ADMIN_TOKEN_KEY, enc);
}

const admin_logged_data = () => {
    var session_data = localStorage.getItem(ADMIN_TOKEN_KEY);
    if (session_data && session_data.length > 0) {
        var decrypted = decrypt(session_data);
        if (decrypted && decrypted.length > 0) {
            var data = JSON.parse(decrypted);
            if (data && data.access_token && data.access_token.length > 0) {
                return data;
            }
        }
    }
    return null;
}

const admin_logged_clear = () => {
    localStorage.setItem(ADMIN_TOKEN_KEY, '');
    localStorage.removeItem(ADMIN_TOKEN_KEY);
}

const has_admin_permission = (PageId) => {
    if (!PageId) {
        return false
    }
    var tmp = admin_logged_data();
    if (tmp != null) {
        for (let i = 0; tmp.permissions !== null && i < tmp.permissions.length; i++) {
            if (tmp.permissions[i].id.toString() === PageId.toString()) {
                return tmp.permissions[i].allowed;
            }
        }
    }
    return false;
}

const set_entity_logged = (data) => {
    const temp = JSON.stringify(data);
    var enc = encrypt(temp);
    localStorage.setItem(ENTITY_TOKEN_KEY, enc);
}

const entity_logged_data = () => {
    var session_data = localStorage.getItem(ENTITY_TOKEN_KEY);
    if (session_data && session_data.length > 0) {
        var decrypted = decrypt(session_data);
        if (decrypted && decrypted.length > 0) {
            var data = JSON.parse(decrypted);
            if (data && data.access_token && data.access_token.length > 0) {
                return data;
            }
        }
    }
    return null;
}

const entity_logged_clear = () => {
    localStorage.setItem(ENTITY_TOKEN_KEY, '');
    localStorage.removeItem(ENTITY_TOKEN_KEY);
}

const has_entity_permission = (PageId) => {
    if (!PageId) {
        return false
    }
    var tmp = entity_logged_data();
    if (tmp != null) {
        for (let i = 0; tmp.permissions !== null && i < tmp.permissions.length; i++) {
            if (tmp.permissions[i].id.toString() === PageId.toString()) {
                return tmp.permissions[i].allowed;
            }
        }
    }
    return false;
}


const generateRandAlphaNumStr = (len) => {
    var rdmString = '';
    for (
        ;
        rdmString.length < len;
        rdmString += Math.random().toString(36).substring(2)
    );
    return rdmString.substring(0, len);
};

const encrypt = (param) => {
    var key = generateRandAlphaNumStr(32);
    var iv = generateRandAlphaNumStr(16);
    var encrypted = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(param),
        CryptoJS.enc.Utf8.parse(key),
        {
            keySize: 128 / 8,
            iv: CryptoJS.enc.Utf8.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        }
    );
    return btoa(key + iv + encrypted.toString());
};

const decrypt = (param) => {
    try {
        param = atob(param);
        param = param.replace(' ', '+');
        var key = CryptoJS.enc.Utf8.parse(param.substring(0, 32));
        var iv = CryptoJS.enc.Utf8.parse(param.substring(32, 32 + 16));
        var encryptedText = param.substring(32 + 16);
        var decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    } catch {
        return '';
    }
};

const DateFormat = (datetxt) => {
    return datetxt ? moment(datetxt).format('DD, MMM YYYY hh:mm A') : '';
}

const set_alert_msg = (msg, type, code) => {
    localStorage.setItem(ALERT_MSG_KEY, msg);
    localStorage.setItem(ALERT_TYPE_KEY, type);
    localStorage.setItem(ALERT_CODE_KEY, code);
}

const get_alert_msg = () => {
    const m = localStorage.getItem(ALERT_MSG_KEY);
    if (m && m.length > 0 && m !== undefined) {
        localStorage.setItem(ALERT_MSG_KEY, '');
        localStorage.removeItem(ALERT_MSG_KEY);
        return m;
    }
    return '';
}

const get_alert_type = () => {
    const m = localStorage.getItem(ALERT_TYPE_KEY);
    var t = 0;
    if (m) {
        try {
            t = parseInt(m);
        } catch (_) {

        }
    }
    return t;
}

const get_alert_code = () => {
    const m = localStorage.getItem(ALERT_CODE_KEY);
    var t = 0;
    if (m) {
        try {
            t = parseInt(m);
        } catch (_) {

        }
    }
    return t;
}

const characterOnly = (inputText) => {
    if (inputText) {
        let patt = /^[A-Za-z]+$/;
        let result = patt.test(inputText);
        return result;
    } else {
        return true;
    }
}

const characterWithApostrophe = (inputText) => {
    if (inputText) {
        let patt = /^[A-Za-z]+$/;
        let result = patt.test(inputText);
        return result;
    } else {
        return true;
    }
}

const lastNameRegex = (inputText) => {
    if (inputText.split("'").length > 2) {
        return false;
    }
    if (inputText.split(".").length > 2) {
        return false;
    }
    if (inputText.split("-").length > 2) {
        return false;
    }
    if (inputText) {
        let patt = /^[a-zA-Z'.-]*$/;
        let result = patt.test(inputText);
        return result;
    } else {
        return true;
    }
}

const characterWithSpace = (inputText) => {
    if (inputText) {
        let patt = /^[A-Za-z ]+$/;
        let result = patt.test(inputText);
        return result;
    } else {
        return true;
    }
}

const numericOnly = (inputText) => {
    if (inputText) {
        let patt = /^[0-9]+$/;
        let result = patt.test(inputText.replaceAll(',',''));
        return result;
    } else {
        return true;
    }
}
const numeric_dec_Only = (inputText) => {
    if (inputText.split(".").length > 2) {
        return false;
    }
    let afetrDec = inputText.split(".")[1];
    if (afetrDec?.length > 2) {
        return false
    }
    if (inputText) {
        let patt = /^[0-9.]*$/;
        let result = patt.test(inputText.toString());
        return result;
    } else {
        return true;
    }
}

const numeric_hash_Only = (inputText) => {
    if (inputText) {
        let patt = /^\d+-?\d*$/;
        let result = patt.test(inputText);
        return result;
    } else {
        return true;
    }
}

const isValidEmail = (inputText) => {
    if (inputText) {
        let patt = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
        let result = patt.test(inputText);
        return result;
    } else {
        return false;
    }
}

const isPAN_No = (inputText) => {
    if (inputText) {
        let patt = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        let result = patt.test(inputText);
        return result;
    } else {
        return false;
    }
}

const isGSTIN_No = (inputText) => {
    if (inputText) {
        let patt = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z0-9]{3}$|^[0-9]{4}[A-Z]{3}[0-9]{5}[0-9A-Z]{3}$|^[0-9]{2}[A-Z]{4}[0-9]{5}[A-Z0-9]{3}$/;
        let result = patt.test(inputText);
        return result;
    } else {
        return false;
    }
}

const isCIN_No = (inputText) => {
    if (inputText) {
        let patt = /^[L/U]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/;
        let result = patt.test(inputText);
        return result;
    } else {
        return false;
    }
}

const isIFSC_Code = (inputText) => {
    if (inputText) {
        let patt = /^[A-Z]{4}[0]{1}[0-9]{6}$/;
        let result = patt.test(inputText);
        return result;
    } else {
        return false;
    }
}

const check_in_entity = (e, list) => {
    for (let i = 0; i < list.length; i++) {
        if (list[i] == e) {
            return true;
        }
    }
    return false;
};

const check_in_array = (e, list) => {
    for (let i = 0; i < list.length; i++) {
        if (list[i].toString() == e.toString()) {
            return true;
        }
    }
    return false;
};
const customReactStyles = {
    option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        backgroundColor: state.isSelected ? '#F1EBF4' : state.isFocused ? '#F1EBF4' : state.isActived ? '#F1EBF4' : 'white', // Change the background color on selected
        color: state.isFocused ? 'black' : 'black',
        '&:active': {
            backgroundColor: '#F1EBF4', // Change this to your desired color
        },
    }),
};

const getSelectValue = (item) => {
    if (item && typeof item === 'object') {
        return item.value;
    }
    if (item && typeof item === 'number') {
        return item;
    }
    return -1;
}


const AdminLoggedData = () => {
    var session_data = localStorage.getItem('token');
    if (session_data !=undefined && session_data != '') {
        return {token:session_data};
    }
    return null;
}

export {
    REG_RESUME_DATA_KEY,
    set_admin_logged,
    admin_logged_data,
    admin_logged_clear,
    has_admin_permission,
    set_entity_logged,
    entity_logged_data,
    entity_logged_clear,
    has_entity_permission,
    set_alert_msg,
    get_alert_msg,
    get_alert_type,
    get_alert_code,
    encrypt,
    decrypt,
    DateFormat,
    characterOnly,
    characterWithSpace,
    characterWithApostrophe,
    numericOnly,
    numeric_dec_Only,
    isValidEmail,
    numeric_hash_Only,
    isPAN_No,
    isGSTIN_No,
    isCIN_No,
    isIFSC_Code,
    check_in_entity,
    check_in_array,
    lastNameRegex,
    customReactStyles,
    getSelectValue,
    AdminLoggedData
}
