import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../assets/IMAGES/logo.png'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import goggle from '../assets/IMAGES/google-play.png'
import app from '../assets/IMAGES/app-store.png'

import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom'

const FrontHeader= ({ name, ...props }) => {
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(false);



  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const CloseButton = () => {
    setHide(true)
  }
  return (
    <>
    <div className='header-fixed'>
      <section className='header-line'>
        <div className='container'>
        <div className='row py-1 d-flex justify-content-between align-content-center'>
        <div className='col-xl-9 col-lg-9 col-md-6 col-sm-12 col-12 d-flex align-items-center mb-2'>
          <marquee behavior="" direction="left" className='text-white p-2'>
          The Shri Amarnathji Yatra will take place from June 29, 2024, to August 19, 2024. We kindly request all yatris not to throw any litter on the yatra track.
          </marquee>
          </div>
          <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 d-flex align-items-center mb-2'>
            <div className='row justify-content-end'>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-3 col-3 d-flex justify-content-end cursor-pointer'>
            <a href="https://play.google.com/store/apps/details?id=com.agov.amarnathyatraapp&pli=1">
             <img src={goggle} alt="" className='w-100' />
             </a>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-3 col-3 d-flex justify-content-end cursor-pointer'>
            <a className='' href='https://apps.apple.com/in/app/nandi-the-yatra-sarthi/id6504807362'>
              <img src={app} alt="" className='w-100' />
              </a>
            </div>
            </div>
            </div>
          </div>
          {/* <div className='row py-1 d-flex justify-content-between align-content-center'>
            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 d-flex align-items-center mb-2'>
              <div>
                <a href="tel:+14464" className='font-15 roboto-medium text-white text-decoration-none'><i class="fas fa-phone-alt"></i> HelpLine No : 14464</a>
              </div>
            </div>
            <div className='col-xl-2 col-lg-2 col-md-4 col-sm-4 col-4 d-flex justify-content-end'>
              <NavLink to='https://jksasb.nic.in/onlineservices/online_service.aspx' className='btn btn-outline-light roboto-medium'>Donate&nbsp;Now</NavLink>
            </div>
           <div className='col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12 d-flex justify-content-xl-end justify-content-lg-end justify-content-md-center justify-content-sm-center justify-content-center'>
            <div className='row d-flex justify-content-xl-end justify-content-lg-end justify-content-md-center justify-content-sm-center justify-content-center'>
            <div className='col-xl-2 col-lg-2 col-md-4 col-sm-4 col-4 d-flex justify-content-end'>
              <NavLink to='https://jksasb.nic.in/onlineservices/online_service.aspx' className='btn btn-outline-light roboto-medium'>Donate&nbsp;Now</NavLink>
            </div>
            <div className='col-xl-2 col-lg-2 col-md-4 col-sm-4 col-4 d-flex justify-content-end cursor-pointer'>
            
            <img src={goggle} alt="" className='w-100' />
            </div>
            <div className='col-xl-2 col-lg-2 col-md-4 col-sm-4 col-4 d-flex justify-content-end cursor-pointer'>
            <img src={app} alt="" className='w-100' />
            </div>
            </div>
            </div> 
          </div> */}
        </div>
      </section>
      <section className='py-2 bg-white'>
        <div className='container'>
          <div className='row d-flex align-items-center justify-content-center'>
            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6'>
              <Link to='/' >
                <img src={logo} className='w-100' alt="" />
              </Link>
            </div>
            <div className='col-xl-9 col-lg-9 col-md-8 col-sm-6 col-6 d-flex justify-content-end '>
              <Navbar expand="lg" className='desktop-view'>
                <Container>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
                    <Nav className="">
                      <Nav.Link as={Link} className='roboto-medium nav-color' to='/home'>Home</Nav.Link>
                      <Nav.Link as={Link} className='roboto-medium nav-color' to='/about'>About</Nav.Link>
                      <Nav.Link as={Link} className='roboto-medium nav-color' to='/DRS'>DRS(J&K)</Nav.Link>
                      <Nav.Link as={Link} className='roboto-medium nav-color' to='/Yatra'>Yatra</Nav.Link>
                      <Nav.Link as={Link} className='roboto-medium nav-color' to='/DoDont'>Do&nbsp;and&nbsp;Don'ts</Nav.Link>
                      <Nav.Link as={Link} className='roboto-medium nav-color' to='/IEC'>IEC&nbsp;Material</Nav.Link>
                      <Nav.Link as={Link} className='roboto-medium nav-color' to='/Gallery'>Gallery</Nav.Link>
                      <Nav.Link as={Link} className='roboto-medium nav-color' to='/Videos'>Videos</Nav.Link>
                      <Nav.Link as={Link} className='roboto-medium nav-color' to='/Maps'>Maps</Nav.Link>
                      <Nav.Link as={Link} className='roboto-medium nav-color' to='/Feedback'>Feedback</Nav.Link>
                      <Nav.Link as={Link} className='roboto-medium nav-color' to='/Contact'>Contact&nbsp;Us</Nav.Link>
                      <Nav.Link as={Link} className='roboto-medium nav-color' to='/DashboardWM'>Dashboard&nbsp;WM</Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
              {/*  */}
              <div onClick={handleShow} className='mobile-view'>
                <i class="fas fa-bars"></i>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Offcanvas show={show} onHide={handleClose} {...props} placement="end" style={{width:'90%'}}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <img src={logo} className='w-75' alt="" />
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Nav.Link as={Link} className='roboto-medium  border-bottom mb-2 nav-color' onClick={handleClose} to='/home'>Home</Nav.Link>
        <Nav.Link as={Link} className='roboto-medium  border-bottom mb-2 nav-color' onClick={handleClose} to='/about'>About</Nav.Link>
        <Nav.Link as={Link} className='roboto-medium  border-bottom mb-2 nav-color' onClick={handleClose} to='/DRS'>DRS(J&K)</Nav.Link>
        <Nav.Link as={Link} className='roboto-medium  border-bottom mb-2 nav-color' onClick={handleClose} to='/Yatra'>Yatra</Nav.Link>
        <Nav.Link as={Link} className='roboto-medium  border-bottom mb-2 nav-color' onClick={handleClose} to='/DoDont'>Do and Don'ts</Nav.Link>
        <Nav.Link as={Link} className='roboto-medium  border-bottom mb-2 nav-color' onClick={handleClose} to='/IEC'>IEC Material</Nav.Link>
        <Nav.Link as={Link} className='roboto-medium  border-bottom mb-2 nav-color' onClick={handleClose} to='/Gallery'>Gallery</Nav.Link>
        <Nav.Link as={Link} className='roboto-medium  border-bottom mb-2 nav-color' onClick={handleClose} to='/Videos'>Videos</Nav.Link>
        <Nav.Link as={Link} className='roboto-medium  border-bottom mb-2 nav-color' onClick={handleClose} to='/Maps'>Maps</Nav.Link>
        <Nav.Link as={Link} className='roboto-medium  border-bottom mb-2 nav-color' onClick={handleClose} to='/Feedback'>Feedback</Nav.Link>
        <Nav.Link as={Link} className='roboto-medium  border-bottom mb-2 nav-color' onClick={handleClose} to='/Contact'>Contact Us</Nav.Link>
        <Nav.Link as={Link} className='roboto-medium  border-bottom mb-2 nav-color' onClick={handleClose} to='/DashboardWM'>Dashboard WM</Nav.Link>
      </Offcanvas.Body>
    </Offcanvas>

{/*  */}
  {/* <div className='container-fluid'>
      <NavLink to='' className='google-icon'>
      <img src={goggle} alt="" className='w-25' />
    </NavLink>
      <NavLink to='' className='app-icon'>
      <img src={app} alt="" className='w-25' />
    </NavLink>
  </div> */}
{/*  */}
  </>
  )
}

export default FrontHeader
