import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './css/style.css'
import { BrowserRouter } from 'react-router-dom';
import Frontroutes from './routes/Frontroutes';


// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

// import AdminRoutes from './routes/AdminRoutes';

const AdminRoutes = lazy(() => import('./routes/AdminRoutes'));


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  // <h1 className='text-danger'>hello</h1>
  <>
    <Suspense fallback={null}>
    <BrowserRouter basename='/'>
      <div className="homeclass">
        <Frontroutes />
      </div>
    </BrowserRouter>
    <BrowserRouter basename='/amarnath_admin'>
        <AdminRoutes />
      </BrowserRouter>
    </Suspense> 

  </>

);
reportWebVitals();
