import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';

Highcharts3D(Highcharts);

const DonutChart = ({ title, data }) => {
    console.log("data and title", title, data);

    useEffect(() => {
        Highcharts.chart('container-' + title, {
            chart: {
                type: 'pie',
                options3d: {
                    enabled: true,
                    alpha: 50,
                }
            },
            title: {
                text: title,
                align: 'left',
                style: {
                    fontSize: '20px',
                }
            },
            plotOptions: {
                pie: {
                    innerSize: 150,
                    depth: 100,
                    startAngle: 46,
                    endAngle: 450,
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            return this.point.name + ': ' + this.point.y;
                        },
                        style: {
                            fontSize: '18px',
                            color: 'black' 
                        }
                    }
                }
            },
            series: [{
                name: 'Data',
                data: data.map((item, index) => ({
                    name: item.label,
                    y: parseFloat(item.count),
                    color: item.color || Highcharts.getOptions().colors[index % Highcharts.getOptions().colors.length]
                }))
            }]
        });
    }, [title, data]);

    const styles = {
        container: {
            height: '500px'
        },
        figure: {
            minWidth: '310px',
            maxWidth: '800px',
            margin: '1em auto'
        }
    };

    return (
        <figure style={styles.figure}>
            <div id={'container-' + title} style={styles.container}></div>
        </figure>
    );
};

export default DonutChart;