import React, { useRef, useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
// import BannerOne from '../assets/IMAGES/banner-4.jpg'
import BannerOne from '../assets/IMAGES/ban-6.jpeg'
// import BannerTwo from '../assets/IMAGES/banner-2.jpg'
import BannerTwo from '../assets/IMAGES/banner-1.jpg'
// import BannerThree from '../assets/IMAGES/banner-3.jpg'
import BannerThree from '../assets/IMAGES/ban-1.jpg'
import sectionone from '../assets/IMAGES/section-1.png'
import shiva from '../assets/IMAGES/om.png'
import shivawhite from '../assets/IMAGES/om-white.png'
import temple from '../assets/IMAGES/temple.png'
import material from '../assets/IMAGES/contract.png'
import dodont from '../assets/IMAGES/to-do-list.png'
// import ganesha from '../assets/IMAGES/section-3-1.png'
import swastika from '../assets/IMAGES/swastika.png'
import shivatwo from '../assets/IMAGES/second-third-2.png'
import image1 from '../assets/IMAGES/sended/send-1.jpeg'
import image2 from '../assets/IMAGES/sended/send-8.jpeg'
import image3 from '../assets/IMAGES/sended/send-3.jpeg'
import image4 from '../assets/IMAGES/sended/send-4.jpeg'
// import image5 from '../assets/IMAGES/i-5.jfif'
// import image6 from '../assets/IMAGES/i-6.jfif'
// import image7 from '../assets/IMAGES/i-7.jfif'
// import image8 from '../assets/IMAGES/i-8.jfif'
import footerup1 from '../assets/IMAGES/footerup-1.png'
import footerup2 from '../assets/IMAGES/footerup-2.png'
import event1 from '../assets/IMAGES/event-1.jpg'
import event2 from '../assets/IMAGES/event-2.jpg'
import event3 from '../assets/IMAGES/event-3.png'
import garbage from '../assets/IMAGES/delete.png'
import subban1 from '../assets/IMAGES/ban-home1.png'
import subban2 from '../assets/IMAGES/ban-home2.png'
import subban3 from '../assets/IMAGES/ban-home3.png'
// import videoyatra from '../assets/video/AMARNATH YATRA 2023 _ Pahalgam to Amarnath Guffa _ सम्पूर्ण जानकारी.mp4'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap';

import goggle from '../assets/IMAGES/google-play.png'
import app from '../assets/IMAGES/app-store.png'
import Feedbackimg from '../assets/IMAGES/feeback.jpg'


const Home = () => {
  const counterRef = useRef(null);
  const circleRef = useRef(null);
  useEffect(() => {
    const counterElement = counterRef.current;
    const circleElement = circleRef.current;

    // Animate the counter

  }, []);

  // animation
  const animationElements = useRef([]);

  const checkIfInView = () => {
    const windowHeight = window.innerHeight;
    const windowTopPosition = window.scrollY;
    const windowBottomPosition = windowTopPosition + windowHeight;

    animationElements.current.forEach(element => {
      const elementHeight = element.offsetHeight;
      const elementTopPosition = element.offsetTop;
      const elementBottomPosition = elementTopPosition + elementHeight;

      if (
        elementBottomPosition >= windowTopPosition &&
        elementTopPosition <= windowBottomPosition
      ) {
        element.classList.add('in-view');
      } else {
        element.classList.remove('in-view');
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkIfInView);
    window.addEventListener('resize', checkIfInView);
    checkIfInView(); // Initial check

    return () => {
      window.removeEventListener('scroll', checkIfInView);
      window.removeEventListener('resize', checkIfInView);
    };
  }, []);


  const styles = {
    zIndex: 1,
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '60px',
    height: '60px',
    transform: 'translate(-50%, -50%)',
    color: '#173b89',
    fontWeight: 600,
    backgroundColor: '#fff',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0 3px 12px -4px rgba(0, 0, 0, 0.1)',
    justifyContent: 'center'
  };

  // modal box
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  return (
    <>
      <Carousel fade>
        <Carousel.Item>
          <img src={BannerThree} className='w-100 height-550' />
        </Carousel.Item>
        <Carousel.Item>
          <img src={BannerOne} className='w-100 height-550' />
        </Carousel.Item>
        <Carousel.Item>
          <img src={BannerTwo} className='w-100 height-550' />
        </Carousel.Item>
      </Carousel>
      {/* section first start*/}
      <section className='mt-5 animation-element slide-up testimonial' ref={(el) => {
        if (el && !animationElements.current.includes(el)) {
          animationElements.current.push(el);
        }
      }}>
        <div className='container'>
          <div className='row d-flex align-items-start'>
            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
              <img src={sectionone} alt="" className='w-100 mb-3' />
            </div>
            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='row'>
                <div className='col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2'>
                  <img src={shiva} className='w-100' alt="" />
                </div>
                <div className='col-xl-11 col-lg-11 col-md-11 col-sm-10 col-10'>
                  <h3 className='roboto-medium color-blue'>About The Shri Amarnath Ji Yatra</h3>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3'>
                  <div className='row d-flex align-items-center justify-content-center'>
                    <div className='col-2'>
                      <div className='circle-home'>
                        <i className="fas fa-check"></i>
                      </div>
                    </div>
                    <div className='col-10'>
                      <p className='roboto-medium color-blue mb-0 font-18'>Ensure proper worship at the Holy Yatra</p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3'>
                  <div className='row d-flex align-items-center justify-content-center'>
                    <div className='col-2'>
                      <div className='circle-home'>
                        <i className="fas fa-check"></i>
                      </div>
                    </div>
                    <div className='col-10'>
                      <p className='roboto-medium color-blue mb-0 font-18'>Arrange safe custody of funds</p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3'>
                  <div className='row d-flex align-items-center justify-content-center'>
                    <div className='col-2'>
                      <div className='circle-home'>
                        <i className="fas fa-check"></i>
                      </div>
                    </div>
                    <div className='col-10'>
                      <p className='roboto-medium color-blue mb-0 font-18'>Undertake Yatra and surrounding area development</p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3'>
                  <div className='row d-flex align-items-center justify-content-center'>
                    <div className='col-2'>
                      <div className='circle-home'>
                        <i className="fas fa-check"></i>
                      </div>
                    </div>
                    <div className='col-10'>
                      <p className='roboto-medium color-blue mb-0 font-18'>
                        Arrange payment of suitable staff emoluments</p>
                    </div>
                  </div>
                </div>
              </div>
              <p className='roboto-regular mt-3'>
             
              Of all Hindu deities, Lord Shiva is highly revered and popular amongst devotees. 
              To pay obeisance to Holy Ice Lingam, the devotees undertake the arduous annual 
              pilgrimage to the Holy Cave Yatra located in the Kashmir Himalayas in the months 
              of June–August. The Holy Yatra is managed by the Shri Amarnathji Yatra Board (SASB),
              which was constituted by an Act of the Jammu & Kashmir State Legislature in 2000.
              The Hon'ble Lieutenant Governor, UT of J&K, is the Chairman of the Yatra Board.

              </p>

              <div className='mt-4'>
                <a href='/About' className='text-deocoration-none btn btn-blue roboto-medium'>Read More</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section first end*/}
      {/* section second start*/}
      <section className='mt-5 bg-second '>
        <div className='container ' >
          <div className='row d-flex justify-content-center'>
            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center'>
              <div className='row'>
                <div className='col-2'>
                  <img src={shivawhite} className='w-100' alt="" />
                </div>
                <div className='col-10'>
                  <h3 className='roboto-medium text-white'>How Can We  Help</h3>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-4 animation-element slide-up testimonial' ref={(el) => {
            if (el && !animationElements.current.includes(el)) {
              animationElements.current.push(el);
            }
          }}>
            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3'>
              <div className='card-second-1'>
                <div className='row'>
                  <div className='col-xl-3 col-lg-3 col-md-4 col-sm-5 col-5'>
                    <img src={temple} alt="" className='w-100' />
                  </div>
                </div>
                <h3 className='roboto-bold mt-4 text-light'>About Yatra</h3>
                <p className='mt-2 text-white'>
                  Shri Amarnath Ji Yatra 2024 shall commence from 29th June, 2024 and conclude on 19th August, 2024
                </p>
                <a href='/Yatra' className='text-white text-decoration-none roboto-medium'>Read More <i className="fas fa-arrow-right ms-2"></i></a>
              </div>
            </div>
            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3'>
              <div className='card-second-2'>
                <div className='row'>
                  <div className='col-xl-3 col-lg-3 col-md-4 col-sm-5 col-5'>
                    <img src={material} alt="" className='w-100' />
                  </div>
                </div>
                <h3 className='roboto-bold mt-4 text-light'>IEC Material</h3>
                <p className='mt-2 text-white'>
                  convey public health messaging in order to support the overarching behaviour change strategy developed.
                </p>
                <a href='/IEC' className='text-white text-decoration-none roboto-medium'>Read More <i className="fas fa-arrow-right ms-2"></i></a>
              </div>
            </div>
            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3'>
              <div className='card-second-3'>
                <div className='row'>
                  <div className='col-xl-3 col-lg-3 col-md-4 col-sm-5 col-5'>
                    <img src={dodont} alt="" className='w-100' />
                  </div>
                </div>
                <h3 className='roboto-bold mt-4 color-redtype'>Do and Don'ts</h3>
                <p className='mt-2 text-dark'>
                  Carry your Aadhaar details for collecting RFID Cards.
                  Don’t drink alcohol, caffeinated drink, or smoke.
                </p>
                <a href='/DoDont' className='color-redtype text-decoration-none roboto-medium'>Read More <i className="fas fa-arrow-right ms-2"></i></a>
              </div>
            </div>
          </div>
        </div>

      </section>
      {/* section second end*/}
      {/* section third start*/}
      <section className='mt-5 animation-element slide-up testimonial' ref={(el) => {
        if (el && !animationElements.current.includes(el)) {
          animationElements.current.push(el);
        }
      }}>
        <div className='container'>
          <div className='row align-items-center justify-content-center'>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12'>
              <img src={Feedbackimg} alt="" className='w-100' />
            </div>
            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
              <div className='card-section-third'>
                <div className='row d-flex justify-content-between'>
                  <div className='col-9'>
                    <h3 className='color-blue roboto-bold'>We are Hindus who believe in Lord Shiva.</h3>
                  </div>
                  <div className='col-3'>
                    <img src={swastika} className='w-100' alt="" />
                  </div>

                </div>
                <h3 className='color-blue mt-3 roboto-medium'>Devotion to Lord Shiva</h3>
                <p className="m-0 blockquote bg-transparent">
                  <span><b>Amarnath :</b>Famous for the natural ice lingam that forms annually.</span>

                </p>
              </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12'>
              <img src={shivatwo} alt="" className='w-100' />
            </div>
          </div>
        </div>
      </section>
      {/* section third end*/}
      {/* section fouth start*/}
      <section className='mt-5 animation-element slide-up testimonial' ref={(el) => {
        if (el && !animationElements.current.includes(el)) {
          animationElements.current.push(el);
        }
      }}>
        <div className='container'>
          <div className='row d-flex justify-content-center'>
            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center'>
              <div className='row'>
                <div className='col-3'>
                  <img src={shiva} className='w-100' alt="" />
                </div>
                <div className='col-9'>
                  <h3 className='roboto-medium color-blue'>Gallery</h3>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex justify-content-center mt-4'>
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
              <img src={image1} alt="" className='w-100 height-250' />
            </div>
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
              <img src={image2} alt="" className='w-100 height-250' />
            </div>
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
              <img src={image3} alt="" className='w-100 height-250' />
            </div>
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
              <img src={image4} alt="" className='w-100 height-250' />
            </div>
            {/* <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
              <img src={image5} alt="" className='w-100 height-250' />
            </div>
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
              <img src={image6} alt="" className='w-100 height-250' />
            </div>
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
              <img src={image7} alt="" className='w-100 height-250' />
            </div>
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
              <img src={image8} alt="" className='w-100 height-250' />
            </div> */}
            <div className='mt-4 d-flex justify-content-center'>
            <a href='/Gallery' className='text-deocoration-none btn btn-blue roboto-medium'>View More</a>
            </div>
          </div>
        </div>
      </section>
      {/* section fouth end*/}
         {/* section fouth start*/}
         <section className='mt-5 animation-element slide-up testimonial' ref={(el) => {
        if (el && !animationElements.current.includes(el)) {
          animationElements.current.push(el);
        }
      }}>
        <div className='container'>
          <div className='row d-flex justify-content-center'>
            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center'>
              <div className='row'>
                <div className='col-3'>
                  <img src={shiva} className='w-100' alt="" />
                </div>
                <div className='col-9'>
                  <h3 className='roboto-medium color-blue'>Videos</h3>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex justify-content-center mt-4'>
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/4QnN0FL8ZzI?si=cs9bbhmdc7mBqeS9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/CXuKmhREuSM?si=P_IlUTEbuy5lY4i8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/ebwaV8i4QbA?si=v17Rib7PBXKuANlT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/yHsSMkcvxkQ?si=aLkmxIegmnWuBBl9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            {/* <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
              <img src={image5} alt="" className='w-100 height-250' />
            </div>
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
              <img src={image6} alt="" className='w-100 height-250' />
            </div>
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
              <img src={image7} alt="" className='w-100 height-250' />
            </div>
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
              <img src={image8} alt="" className='w-100 height-250' />
            </div> */}
            <div className='mt-4 d-flex justify-content-center'>
            <a href='/Videos' className='text-deocoration-none btn btn-blue roboto-medium'>View More</a>
            </div>
          </div>
        </div>
      </section>
      {/* section fouth end*/}
      {/* section five start*/}
      <section className='mt-5 animation-element slide-up testimonial' ref={(el) => {
        if (el && !animationElements.current.includes(el)) {
          animationElements.current.push(el);
        }
      }}>

        <div className='container'>
          <div className='row d-flex justify-content-center'>
            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center'>
              <div className='row align-items-center'>
                <div className='col-2'>
                  <img src={shiva} className='w-100' alt="" />
                </div>
                <div className='col-10'>
                  <h3 className='roboto-medium color-blue mb-0'>LATEST EVENTS</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container mt-5'>
          <div className="row ">
            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3'>
              <div className='event-box event-scroll'>
                <h3 class="color-blue mb-4 roboto-medium">What's New</h3>
                <div className='row mb-2 border-bottom inner-card-event p-2 align-items-center'>
                  <div className='col-xl-3 col-lg-3 col-lg-3 col-sm-3 col-3'>
                    <img src={event1} alt="" className='w-100' />
                  </div>
                  <div className='col-xl-9 col-lg-9 col-lg-9 col-sm-9 col-9'>
                    <p className='mb-0'>Welcome To Directorate of Rural Sanitation, J&K</p>
                  </div>
                </div>
                <div className='row mb-2 border-bottom inner-card-event p-2 align-items-center' >
                  <div className='col-xl-3 col-lg-3 col-lg-3 col-sm-3 col-3'>
                    <img src={event2} alt="" className='w-100' />
                  </div>
                  <div className='col-xl-9 col-lg-9 col-lg-9 col-sm-9 col-9'>
                    <p className='mb-0'>Welcome To Directorate of Rural Sanitation, J&K</p>
                  </div>
                </div>
                <div className='row mb-2 border-bottom inner-card-event p-2' >
                  <div className='col-xl-3 col-lg-3 col-lg-3 col-sm-3 col-3'>
                    <img src={event3} alt="" className='w-100' />
                  </div>
                  <div className='col-xl-9 col-lg-9 col-lg-9 col-sm-9 col-9'>
                    <p className='mb-0'>Welcome To Directorate of Rural Sanitation, J&K</p>
                  </div>
                </div>
                <div className='row mb-2 border-bottom inner-card-event p-2 align-items-center'>
                  <div className='col-xl-3 col-lg-3 col-lg-3 col-sm-3 col-3'>
                    <img src={event1} alt="" className='w-100' />
                  </div>
                  <div className='col-xl-9 col-lg-9 col-lg-9 col-sm-9 col-9'>
                    <p className='mb-0'>Welcome To Directorate of Rural Sanitation, J&K</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3'>
              <div class="cms-evideo cms-evideo-5 relative">
                <div class="cms-lazy cms-radius-trbr-16 p-tb-200" style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                  <div class="cms-btn-video layout-3 m-lr-auto cms-transition">
                    <i class="fas fa-play ms-transition text-14  cms-icon cmsi-play" data-bs-toggle="modal" data-bs-target="#exampleModal"></i><span class="cms-text empty-none"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3'>
              <div className='event-box event-scroll'>
                <h3 class="color-blue mb-4 roboto-medium">Our Campaigns</h3>
                <div className='row mb-2 border-bottom '>
                  <div className='col-xl-2 col-lg-2 col-lg-3 col-sm-3 col-3'>
                    <img src={garbage} alt="" className='w-100' />
                  </div>
                  <div className='col-xl-10 col-lg-10 col-lg-9 col-sm-9 col-9'>
                    <h5 className='roboto-medium color-blue'>Solid Waste Management:</h5>
                    <p>
                      Solid Waste Management:
                      Effective management of solid waste by at least 80% of households and all public places</p>
                  </div>
                </div>
                <div className='row mb-2 border-bottom '>
                  <div className='col-xl-2 col-lg-2 col-lg-3 col-sm-3 col-3'>
                    <img src={garbage} alt="" className='w-100' />
                  </div>
                  <div className='col-xl-10 col-lg-10 col-lg-9 col-sm-9 col-9'>
                    <h5 className='roboto-medium color-blue'>Swachhata Hi Seva Campaign:</h5>
                    <p>
                      villages have been made visually cleaned and 7016.3 kg of legacy waste has been disposed off.</p>
                  </div>
                </div>
                <div className='row mb-2 border-bottom '>
                  <div className='col-xl-2 col-lg-2 col-lg-3 col-sm-3 col-3'>
                    <img src={garbage} alt="" className='w-100' />
                  </div>
                  <div className='col-xl-10 col-lg-10 col-lg-9 col-sm-9 col-9'>
                    <h5 className='roboto-medium color-blue'>Visual cleanliness:</h5>
                    <p>
                      A village will be classified as visually clean if 80% of households and all public places are observed to have minimal litter and minimal stagnant water.</p>
                  </div>
                </div>
                <div className='row mb-2 border-bottom '>
                  <div className='col-xl-2 col-lg-2 col-lg-3 col-sm-3 col-3'>
                    <img src={garbage} alt="" className='w-100' />
                  </div>
                  <div className='col-xl-10 col-lg-10 col-lg-9 col-sm-9 col-9'>
                    <h5 className='roboto-medium color-blue'>Liquid Waste Management:</h5>
                    <p>
                      Effective management of liquid waste by at least 80% of households and all public places (including the Primary schools, Panchayat Ghar and Anganwadi Centre).</p>
                  </div>
                </div>
                <div className='row mb-2 border-bottom '>
                  <div className='col-xl-2 col-lg-2 col-lg-3 col-sm-3 col-3'>
                    <img src={garbage} alt="" className='w-100' />
                  </div>
                  <div className='col-xl-10 col-lg-10 col-lg-9 col-sm-9 col-9'>
                    <h5 className='roboto-medium color-blue'>
                      ODF-Sustainability:</h5>
                    <p>
                      That all households in a village, as well as the Primary Schools, Panchayat Ghar and Anganwadi Centre, have access to a toilet and that continued behavior change communication is ensured in the village through Information</p>
                  </div>
                </div>
                <div className='row mb-2 border-bottom '>
                  <div className='col-xl-2 col-lg-2 col-lg-3 col-sm-3 col-3'>
                    <img src={garbage} alt="" className='w-100' />
                  </div>
                  <div className='col-xl-10 col-lg-10 col-lg-9 col-sm-9 col-9'>
                    <h5 className='roboto-medium color-blue'>
                      The verticals of SBM-G Phase-II are:</h5>
                    <p>
                      Individual Household Latrines<br></br>
                      Retrofitting of toilets<br></br>
                      Construction of Community Sanitary Complexes (CSCs)<br></br>
                      GOBAR-dhan (Galvanizing Organic BioAgro Resources–dhan)<br></br>
                    </p>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>

      </section>
      <section className='mt-5 animation-element slide-up testimonial' ref={(el) => {
        if (el && !animationElements.current.includes(el)) {
          animationElements.current.push(el);
        }
      }}>
        <div className='container mt-5'>
          <div className='row d-flex justify-content-center'>
            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center'>
              <div className='row align-items-center'>
                <div className='col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2'>
                  <img src={shiva} className='w-100' alt="" />
                </div>
                <div className='col-xl-11 col-lg-11 col-md-10 col-sm-10 col-10'>
                  <h3 className='roboto-medium color-blue mb-0'>Discover the Spiritual Amarnath Ji Yatra</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='mt-5 animation-element slide-up testimonial' ref={(el) => {
        if (el && !animationElements.current.includes(el)) {
          animationElements.current.push(el);
        }
      }}>
        <div className='container mt-5'>
          <div className='row'>
            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3'>
              {/* <h3>Welcome to the Amarnath Ji Yatra 2024</h3> */}

              <p className='pt-3'>The Amarnath Yatra is one of the most revered pilgrimages in Hinduism, attracting devotees from all over India and the world. The journey leads pilgrims to the sacred Amarnath Cave, located in the Himalayas, where a naturally formed ice lingam symbolizes Lord Shiva. </p>
              <strong>Key Timelines</strong>
              <p>The Amarnath Yatra 2024 is expected to begin on June 28 and conclude on August 22, coinciding with the Shravan month as per the Hindu calendar. It is advisable to check the official Amarnath Yatra website for the latest updates on dates and schedules.</p>
              <strong>Yatra Routes</strong>
              <p>There are two main routes to reach the Amarnath Cave:</p>
              <p><strong>Baltal Route:</strong> This is a shorter but steeper route, approximately 14 km from Baltal to the cave. It is preferred by pilgrims who are physically fit and looking for a quicker journey.</p>
              <p><strong>Pahalgam Route:</strong> This is a longer route, about 36 km, and is considered less strenuous compared to the Baltal route. It passes through scenic landscapes and is ideal for those who wish to take a more gradual ascent.</p>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3'>
              <Carousel fade>
                <Carousel.Item>
                  <img src={subban1} className='w-100 height-suban' />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={subban2} className='w-100 height-suban' />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={subban3} className='w-100 height-suban' />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </section>
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog  modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Yatra Video</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              {/* <video src={videoyatra} controls className='w-100 height-500' autoPlay></video> */}
          

              <iframe className='w-100' height="500" src="https://www.youtube.com/embed/K2c7vqQkKbU?si=KRmUfPV17tcwmrq_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
           
          </div>
        </div>
      </div>
      {/* section five end*/}
      {/* section five start*/}
      <section className='mt-5 animation-element slide-up testimonial' ref={(el) => {
        if (el && !animationElements.current.includes(el)) {
          animationElements.current.push(el);
        }
      }}>
        <div className='container'>
          <div className='row d-flex justify-content-center'>
            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center'>
              <div className='row align-items-center'>
                <div className='col-2'>
                  <img src={shiva} className='w-100' alt="" />
                </div>
                <div className='col-10'>
                  <h3 className='roboto-medium color-blue mb-0'>WAYS WE CAN HELP</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container mt-5'>
          <div className="row align-items-center position-relative background-row">
            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
              <div className="sigma_cta primary-bg">
                <img className="d-none d-lg-block" src={footerup1} alt="cta" />
                <div className="sigma_cta-content">
                  {/* <span className="fw-600 custom-primary">Need Help, Call Our HOTLINE!</span> */}
                  <h4 className="text-white">
                    <a href="tel:+18001807198" className="text-decoration-none text-white">
                      +18001807198
                    </a>
                  </h4>
                </div>
              </div>
            </div>
            <div className='col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6'>
              <span className="sigma_cta-sperator d-none d-lg-flex" style={{ styles }}>OR</span>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
              <div className="sigma_cta primary-bg">
                <div className="sigma_cta-content">
                  <h4 className="text-white">
                    <a href="tel:+18001807199" className="text-decoration-none text-white">
                      +18001807199
                    </a>
                  </h4>
                </div>
                <img className="d-none d-lg-block" src={footerup2} alt="cta" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section five end*/}

      {/*  */}
      <Modal show={show} onHide={handleClose}  centered>
        <Modal.Header closeButton>
          <Modal.Title>Download the app from the Play Store or App Store.
</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-6'>
              <a href="https://play.google.com/store/apps/details?id=com.agov.amarnathyatraapp&pli=1">
             
              <img src={goggle} alt="" className='w-100' />
              </a>
              
            </div>
            <div className='col-6'>
              <a className='' href='https://apps.apple.com/in/app/nandi-the-yatra-sarthi/id6504807362'>
              <img src={app} alt="" className='w-100' />
              </a>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>

    </>
  )
}

export default Home